<template>
  <v-main class="back">
    <v-container class="fill-height" fluid>
      <v-row justify="center" align="center" no-gutters>
        <v-col cols="12" md="3">
          <v-card elevation="2" class="rounded">
            <v-card-title primary-title class="justify-center">
              <v-img
                src="../assets/logo_link.png"
                max-width="300"
                class="mb-10 mt-10"
              ></v-img>
            </v-card-title>

            <v-card-text>
              <v-form :lazy-validation="lazy" ref="form">
                <v-text-field
                  outlined
                  label="Seu CPF"
                  :rules="[v => !!v || 'Infome um CPF para continuar']"
                  name="cpf"
                  v-facade="'###.###.###-##'"
                  prepend-icon="mdi-account"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  v-model="user.cpf"
                  required
                  autocomplete="on"
                />

                <v-text-field
                  outlined
                  id="password"
                  label="Senha"
                  name="Senha"
                  :rules="[v => !!v || 'Infome uma senha para continuar']"
                  required
                  autocomplete="new-password"
                  prepend-icon="mdi-key"
                  type="password"
                  v-model="user.password"
                  @keyup.enter="login()"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-col cols="12">
                <v-btn
                  block=""
                  large=""
                  color="#0062DA"
                  class="white--text"
                  @click="login()"
                  :loading="loading_login"
                  >Entrar</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  props: ["expired"],
  data() {
    return {
      user: {
        cpf: "",
        password: ""
      },
      loading: false,
      loading_login: false,
      disable: false,
      lazy: false
    };
  },
  mounted() {
    if (window.localStorage.token) {
      this.$router.replace("/home");
    } else {
      localStorage.removeItem("token");
    }
  },
  created() {
    if (this.expired) {
      this.$toast.error(
        "Sua sessão expirou. faça login novamente para continuar",
        { position: "top-right" }
      );
    }
  },

  methods: {
    login() {
      this.message = "";
      this.error = "";
      this.loading_login = true;
      this.$http
        .auth({
          cpf: this.user.cpf,
          password: this.user.password
        })
        .then(response => {
          localStorage.setItem("token", response.data.accessToken);
          this.$router.push("/home");
          this.loading_login = false;
        })
        .catch(error => {
          this.loading_login = false;
          if (error.response.status === 401) {
            this.$toast.error("Senha incorreta para o CPF informado!", {
              position: "top-right"
            });
          } else if (error.response.status === 404) {
            this.$toast.error("Usuario não encontrado!", {
              position: "top-right"
            });
          } else if (error.response.status === 403) {
            this.$toast.error(
              "Você não tem permissão para acessar esse recurso!",
              {
                position: "top-right"
              }
            );
          } else {
            this.$toast.error(error.response.data.message, {
              position: "top-right"
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.back {
  background: #f4f4f4;
}
.rounded {
  border-radius: 15px !important;
}
</style>
